import "./style/index.less";

export default {
    name: "marzipano",
    requires: ["iframe-api"],
    load(app) {
        const MarzipanoController = require("./src/marzipanoController").default;
        const { iframeApi } = app.modules;

        const marzipanoController = new MarzipanoController();
        iframeApi.addApiMethod("openFromUrl", url => marzipanoController.openFromUrl(url));
        marzipanoController.openFromUrl("/assets/modules/marzipano/images/panorama2.jpg");
        iframeApi.addApiMethod("autoRotate", turn => marzipanoController.autoRotate(turn));

        return marzipanoController;
    },
    unload() {},
};
